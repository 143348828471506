// third-party packages
import React, { useState, useEffect } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { themeActions } from '../../../store/theme';
import useScrollPosition from '@react-hook/window-scroll';
import QrReader from 'react-qr-reader';

//services
import UserService from '../../../services/UserService';
import { authActions } from '../../../store/auth';

//components
import NavigationItems from './MainMenu/NavigationItems';
import SideMenu from './MainMenu';
import Container from '../Container';
import PersonalMenu from './PersonalMenu';
import Logo from '../../Logo';
import MenuToggler from './MenuToggler';
import Modal from '../../../components/Modal';

//material-ui
import { withWidth, makeStyles } from '@material-ui/core';
import { Icon } from "@material-ui/core";
import Button from '@material-ui/core/Button';

//icons
import IconButton from "@material-ui/core/IconButton";
import Brightness3Icon from "@material-ui/icons/Brightness3";
import Brightness7Icon from "@material-ui/icons/Brightness7";
import CropFreeIcon from '@material-ui/icons/CropFree';

const Header = props => {
    const classes = useStyles();
    const scrollY = useScrollPosition(60 /*fps*/);
    const theme = useSelector(state => state.theme.currentTheme);
    const loggedIn = useSelector(state => state.auth.isLoggedIn);
    const dispatch = useDispatch();
    const history = useHistory();
    const isStudent = localStorage.getItem('permission') && localStorage.getItem('permission').includes('student');

    const [currentPage, setCurrentPage] = useState(null);
    const [sideMenuOpen, setSideMenuOpen] = useState(false);
    const [atTop, setAtTop] = useState(true);
    const [modalFocus, setModalFocus] = useState(null);

    const toggleThemeHandler = () => {
        dispatch(themeActions.toggle());
    };

    const sideMenuToggleHandler = () => {
        if (!sideMenuOpen) {
            setAtTop(false);
        }
        setSideMenuOpen(!sideMenuOpen);
    }

    const logInHandler = () => {
        UserService.authorization();
    }

    const logOutHandler = () => {
        dispatch(authActions.logout());
        history.replace('/');
    }

    const handleScan = data => {
        setTimeout(() => {
            if (data) {
                console.log(data)
                setModalFocus(null);
                window.location = data;
            }
        }, 500);
    }

    useEffect(() => {
        let newPage = props.location.pathname
        if (currentPage == null || currentPage !== newPage) {
            setCurrentPage(newPage);
            setSideMenuOpen(false);
            setAtTop(true);
        }
    }, [currentPage, props.location.pathname]);

    if (scrollY > 50 && atTop === true) {
        setAtTop(false);
    }
    if (scrollY <= 50 && atTop === false && !sideMenuOpen) {
        setAtTop(true);
    }

    return (
        <>
            <header id="header" className={classes.Header
                + (sideMenuOpen ? " sideMenuOpen" : "")
                + (atTop && (
                    props.location.pathname === '/' ||
                    props.location.pathname.split('/').find(name => name === 'activityInfo') ||
                    props.location.pathname.split('/').find(name => name === 'callback')
                ) ? " atTop" : "")
            }>
                <Container className="headerWrapper">
                    <Logo className="logo" scale='0.8' />
                    <NavigationItems />
                    <div className="controls">
                        {isStudent ? <IconButton
                            className="scanSignoutQrCodeBtn"
                            edge="end"
                            color="inherit"
                            aria-label="mode"
                            onClick={() => setModalFocus('qrScan')}
                        >
                            <CropFreeIcon />
                        </IconButton> : null}
                        <IconButton
                            className="toggleThemeBtn"
                            edge="end"
                            color="inherit"
                            aria-label="mode"
                            onClick={toggleThemeHandler}
                        >
                            {theme === 'dark' ? <Brightness7Icon /> : <Brightness3Icon />}
                        </IconButton>
                        {loggedIn ? (
                            <PersonalMenu logout={logOutHandler} />
                        ) : (
                            <Button
                                className="loginBtn"
                                variant="contained"
                                color="primary"
                                onClick={logInHandler}
                                startIcon={['xs', 'sm'].includes(props.width) ? null : <Icon className="fas fa-user"></Icon>}
                            >
                                登入
                            </Button>
                        )
                        }
                        <MenuToggler
                            active={sideMenuOpen}
                            click={sideMenuToggleHandler}
                            width="25" height="22" lineHeight="4"
                        />
                    </div>
                </Container>
            </header>
            <SideMenu active={sideMenuOpen} />
            <Modal
                className={classes.Modal}
                opened={modalFocus === 'qrScan'}
                handleClose={() => setModalFocus('')}
            >
                <div className="addStudentModal content">
                    <h2 id="title">掃描活動簽退QRCode</h2>
                    <QrReader
                        delay={2000}
                        onError={err => console.error(err)}
                        onScan={handleScan}
                        style={{ width: '100%' }}
                    />
                </div>
            </Modal>
        </>
    );
};

export default withWidth()(withRouter(Header));


const useStyles = makeStyles(theme => ({
    Header: {
        position: "fixed",
        zIndex: "500",
        top: "0",
        color: theme.palette.text.primary,
        padding: "14px 0",
        backgroundColor: theme.palette.background.paper,
        width: "100%",
        boxShadow: "0px 6px 6px rgba(0, 0, 0, 0.1)",
        transition: "0.2s",
        "&.atTop": {
            backgroundColor: "transparent",
            boxShadow: "none",
            "& .navItems": {
                "& a": {
                    color: "#FFFFFF",
                    '&:after': {
                        backgroundColor: '#FFFFFF'
                    }
                }
            },
            "& .logomark path": {
                fill: "#FFFFFF"
            },
            "& .logo:hover": {
                color: "#FFFFFF"
            },
            "& .logotype, & .PersonalMenuToggler, & .toggleThemeBtn, & .scanSignoutQrCodeBtn": {
                color: "#FFFFFF"
            },
            "& .bar, & .controls .loginBtn": {
                backgroundColor: '#FFFFFF'
            }
        },
        "& .headerWrapper": {
            boxSizing: "border-box",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
        },
        "& .navItems": {
            display: 'none',
            listStyle: 'none'
        },
        "& .controls": {
            display: 'flex',
            alignItems: 'center',
            "& .MuiIconButton-root": {
                marginRight: '5px',
                padding: '5px',
            },
            "& .loginBtn": {
                backgroundColor: "#FFDDCB",
                borderRadius: "50px",
                padding: "5px 15px",
                color: theme.palette.primary.main,
                boxShadow: "none",
                marginRight: "10px",
                fontSize: 12
            },
            "& .fas": {
                fontSize: "15px"
            }
        },
        "&.sideMenuOpen": {
            boxShadow: "none",
        },
        [theme.breakpoints.up('xl')]: {
            "& .navItems": {
                display: 'flex'
            }
        }
    },
    [theme.breakpoints.up('sm')]: {
        Header: {
            "& .controls": {
                "& .MuiIconButton-root": {
                    marginRight: '5px',
                },
                "& .loginBtn": {
                    backgroundColor: "#FFDDCB",
                    borderRadius: "50px",
                    padding: "6px 20px",
                    color: theme.palette.primary.main,
                    boxShadow: "none",
                    marginRight: "10px",
                    fontSize: 12
                }
            },
        }
    },
}));
